.App {
  text-align: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #ccccccd4;
  font-family: OpenSans-Regular, serif;
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: local('OpenSans-Regular'), url(./assets/fonts/OpenSans-Regular.ttf) format('truetype');
}

.App-logo {
  cursor: pointer;
  height: 40vmin;
  pointer-events: none;
  padding: 1rem;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.signin-container,
.forgot-container {
  width: 100%;
  max-width: 600px;
  margin-left: 3rem;
  margin-top: 5rem;
}
.login-page,
.forgot-page {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.signin-form,
.forgot-form {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

/* .Toastify__toast-container {
  background: black;
} */


.signin-form::placeholder {
  color: #ccccccd4;
  opacity: 1; /* Firefox */
}

.signin-form::-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ccccccd4;
}

.signin-form::-ms-input-placeholder { /* Microsoft Edge */
  color: #ccccccd4;
}

.input-form {
  border-radius: 8px;
  border: 0;
  font-family: OpenSans-Regular, serif;
  font-size: 16px;
  height: 50px;
  margin-bottom: 1rem;
  outline: none;
  padding-left: 1.5rem;
}

.submit-btn {
  background: linear-gradient(90deg, rgb(107 77 158) 0%, rgb(22 116 232) 100%);
  border-radius: 8px;
  border: 0;
  cursor: pointer;
  font-family: OpenSans-Regular, serif;
  font-size: 18px;
  height: 50px;
  margin-top: 1rem;
  min-width: 200px;
}
.submit-btn-disabled {
  background: linear-gradient(90deg, rgb(107 77 158) 0%, rgb(22 116 232) 100%);
  border-radius: 8px;
  border: 0;
  cursor: pointer;
  font-family: OpenSans-Regular, serif;
  font-size: 18px;
  height: 50px;
  margin-top: 1rem;
  min-width: 200px;
  opacity: 0.5;
}

.text-white {
  color: white;
}

.text-red {
  color: red;
}

.text-center {
  text-align: center;
}

.text-link {
  color: black;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}

.margin-0 {
  margin: 0;
}

.reset-btn {
  margin-top: 5rem;
}

header {
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem;
}

.container {
  width: 100%;
  height: calc(100% - 59px);
  padding: 0.5rem 0;
}

.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 76px);
}

.add-user-icon,
.manage-user-icon {
  height: 60px;
  margin-right: 1rem;
}

.user-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #3e3263;
}

.add-user-form {
  background: white;
  padding: 2rem;
  max-width: 60%;
  margin: auto;
  border-radius: 8px;
  /* margin-left: 6rem; */
  margin-top: 2rem;
}

.form-field {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 1rem;
  align-items: center;
  text-align: left;
}

.form-field label {
  min-width: 220px;
}

.form-field input {
  margin-left: 2rem;
  height: 30px;
  width: 100%;
  max-width: 370px;
  padding: 0 .5rem;
}

.user-card-container {
  display: flex;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.edit-user-btn {
  background: #3a98da;
  color: white;
  font-weight: 600;
  width: 200px;
  border-radius: 8px;
  border: 0;
  cursor: pointer;
  font-family: OpenSans-Regular, serif;
  font-size: 18px;
  height: 50px;
  min-width: 200px;
}
.delete-user-btn {
  background: #d64b4b;
  color: white;
  font-weight: 600;
  width: 200px;
  border-radius: 8px;
  border: 0;
  cursor: pointer;
  font-family: OpenSans-Regular, serif;
  font-size: 18px;
  height: 50px;
  min-width: 200px;
}

.user-profile-icon {
  width: 120px;
  height: 120px;
  padding: 1rem;
}

.user-profile-info {
  padding: 1rem;
}

.user-btn-container {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  justify-content: space-evenly;
}

.total-user-icon {
  width: 50px;
}

img {
  cursor: pointer;
}

.user-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-radius: 20px;
  padding: 1rem;
  flex-wrap: wrap;
  /* width: 800px; */
  max-width: 100%;
  text-align: left;
  flex-wrap: wrap;
  margin: 0.5rem;
  min-width: 730px;
}

.council-user-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-radius: 20px;
  flex-wrap: wrap;
  /* width: 800px; */
  max-width: 100%;
  text-align: left;
  flex-wrap: wrap;
}

.user-card-profile,
.total-user-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.text-black {
  color: black;
}

.font-bold {
  font-weight: 600;
}

.user-card-profile div p {
  margin: 0;
  margin-top: 0.5rem;
  font-weight: 600;
}

h1, h2, h3 {
  color: #3e3263;
  margin: 0;
}

.council-total-user-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: .5rem 1rem;
  border: 2px solid #3e3263;
  border-radius: 20px;  
  width: 152px;
  margin-left: 1rem;
}

.total-user-container {
  padding: 1.5rem 1rem;
  border: 2px solid #3e3263;
  border-radius: 20px;
  width: 152px;
  margin-left: 1rem;
}

.total-user-container p {
  font-size: 29px;
  font-weight: 600;
  margin: 0;
  margin-left: 1rem;
}

.user-management-container {
  margin-left: 5rem;
  margin-bottom: 2rem;
}

.topic-list-container {
  display: flex;
  justify-content: 'space-between';
  margin-top: 1rem;
}

.topics-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}

.council-management-container {
  padding: 6rem;
  padding-top: 1rem;
}

.council-user-container {
  width: 100%;
  box-sizing: border-box;
  max-width: 790px;
  background: white;
  padding: 1rem;
  border-radius: 17px;
}

.MuiTableCell-head,
.MuiTableCell-body {
  text-align: center !important;
}

lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}


@media only screen and (max-width: 768px) {
  .App-logo {
    height: 250px;
  }
  .login-page,
  .forgot-page {
    flex-direction: column;
  }
  .signin-container,
  .forgot-container {
    margin: 0;
  }
  .add-user-form {
    margin: 0.5rem;
    margin-top: 1rem;
    max-width: 100%;
    /* padding: 1rem; */
  }
  .form-field {
    flex-direction: column;
    align-items: flex-start;
  }
  .form-field input {
    margin: 0;
    margin-top: 0.5rem;
  }
  .council-total-user-container {
    width: 100%;
    padding: 0.1rem 1rem;
  }
  .total-user-container {
    width: 100%;
  }
  .user-card {
    min-width: 230px;
  }
  .user-btn-container {
    margin: 0 auto;
    flex-direction: row;
  }
  .user-btn-container button {
    width: 150px;
    min-width: 60px;
  }
  .topic-list-container {
    flex-direction: column;
  }
  .topic-list-container .topics-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .topics-container {
    margin-top: 1rem;
  }
  .council-user-container {
    max-width: 100%;
  }
  .council-management-container {
    padding: 0.5rem;
  }
  .submit-btn {
    width: 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width:1024px) {
  .user-management-container {
    margin-left: 0;
  }
}